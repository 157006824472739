<template>
  <div class="space-y-4">
    <asom-alert v-if="error" variant="error" :error-message="error" />
    <asom-card title="Filters">
      <div class="grid sm:grid-cols-1 md:grid-cols-2 md:gap-x-6 gap-4">
        <asom-form-field label="Line">
          <asom-input-select
            :disabled="!canSelectLine"
            placeholder="Select Line"
            v-model="filters.line"
            :options="lineOptions"
          />
        </asom-form-field>
        <asom-form-field label="Stations">
          <asom-multi-select
            placeholder="Select Stations"
            v-model="filters.stations"
            :options="stationOptions"
          />
        </asom-form-field>
        <asom-form-field label="CP Update No">
          <asom-input-text v-model="filters.cpUpdateNo" />
        </asom-form-field>
        <asom-form-field label="Date Created">
          <asom-input-date-range
            v-model="filters.notificationDate"
            datepicker-placement="bottom"
          />
        </asom-form-field>
        <asom-form-field label="Acknowledged By Date">
          <asom-input-date-range
            v-model="filters.acknowledgedByDate"
            datepicker-placement="bottom"
          />
        </asom-form-field>
        <div class="grid grid-cols-2 gap-4">
          <asom-form-field label="Acknowledgement Status">
            <asom-input-radio-group
              horizontal
              class="pt-4"
              v-model="filters.isAcknowledgedOnly"
              :options="acknowledgements"
            />
          </asom-form-field>
          <asom-form-field label="Expired">
            <asom-input-radio-group
              horizontal
              class="pt-4"
              v-model="filters.isExpiredOnly"
              :options="isExpiredOptions"
            />
          </asom-form-field>
        </div>
      </div>
      <template #footer>
        <asom-button
          text="Reset Filters"
          variant="secondary"
          @click="resetFilters"
        />
        <asom-button text="Apply" @click="getPageData(true)" />
      </template>
    </asom-card>

    <div v-if="isLoading" class="text-center">
      <asom-icon icon="spinner" class="animate-spin" />
    </div>
    <div v-else class="space-y-4">
      <asom-card title="Acknowledgement Summary By Stations">
        <asom-client-table
          :columns="[
            'station',
            'line',
            'total',
            'acknowledged',
            'pendingAcknowledgement',
          ]"
          :data="stationList"
        >
        </asom-client-table>
      </asom-card>
      <asom-card title="Acknowledgement Status Details">
        <asom-client-table
          :columns="[
            'cpUpdateDetails',
            'recipientName',
            'recipientRole',
            'recipientStationName',
            'recipientLine',
            'status',
            'dateOfAckNowledgement',
          ]"
          :sortableColumns="[
            'cpUpdateDetails',
            'status',
            'dateOfAckNowledgement',
            'recipientName',
            'recipientRole',
            'recipientStationName',
            'recipientLine',
          ]"
          :data="itemList"
        >
          <template v-slot:header_cpUpdateDetails>CP Update No</template>
          <template v-slot:header_dateOfAckNowledgement
            >Acknowledgement Date</template
          >
          <template v-slot:header_recipientStationName>Station</template>
          <template v-slot:header_recipientLine>Line</template>
          <template v-slot:status="scopedSlots">
            {{ acknowlegementStatus(scopedSlots.rowData) }}
          </template>
          <template v-slot:cpUpdateDetails="scopedSlots">
            <button
              @click="navigateToView(scopedSlots.rowData)"
              class="text-button underline font-semibold"
            >
              {{ get(scopedSlots.data, "cpUpdateNo") }}
            </button>
          </template>
          <template v-slot:dateOfAckNowledgement="scopedSlots">
            <span>{{
              scopedSlots.data ? displayUtcDate(scopedSlots.data) : "NA"
            }}</span>
          </template>
          <template v-slot:isAcknowledged="scopedSlots">
            <span>{{ scopedSlots.data ? "YES" : "NO" }}</span>
          </template>
        </asom-client-table>
      </asom-card>
    </div>
  </div>
</template>

<script>
import get from "lodash.get";
import isEmpty from "lodash.isempty";
import groupBy from "lodash.groupby";
import moment from "moment";
import { displayUtcDate, parseDateTime, parseDateTimeToUtc } from "@/helpers/dateTimeHelpers";
import { getAcknowledgementSummary } from "../../../services/opsComms.service";
import { mapGetters } from "vuex";
import { setStorage, getStorage } from "@/helpers/sessionStorage";

export default {
  data() {
    return {
      isLoading: false,
      itemList: [],
      error: null,
      filters: {
        cpUpdateNo: null,
        line: this.$store.getters["auth/userLineOption"],
        stations: null,
        notificationDate: {
          start: moment()
            .subtract(1, "months")
            .toDate(),
          end: moment()
            .add(1, "months")
            .toDate(),
        },
        acknowledgedByDate: {
          start: moment()
            .subtract(1, "months")
            .toDate(),
          end: moment()
            .add(1, "months")
            .toDate(),
        },
        isAcknowledgedOnly: null,
        isExpiredOnly: "false",
      },
      
      searchVal: null,
      filterEcho: 0,
    };
  },
  mounted() {
    this.searchVal = getStorage("cp-update-reports");
    if(this.searchVal) {
      this.filterEcho = 1;

      // UTC时间转换成对应的本地时间
      if(this.searchVal.dateRange && this.searchVal.dateRange.start)  this.searchVal.dateRange.start = moment.utc(this.searchVal.dateRange.start).toDate();
      if(this.searchVal.dateRange && this.searchVal.dateRange.end) this.searchVal.dateRange.end = moment.utc(this.searchVal.dateRange.end).toDate();
      this.filters = this.searchVal;
    }
    this.$nextTick(() => {
      this.checkURLQuery();
      this.getPageData();
    })
  },
  watch: {
    "filters.line": function(newValue) {
      if(!this.filterEcho || this.filterEcho > 1){
        if (newValue) {
          this.filters.stations = null;
        }
      }
    },
  },
  computed: {
    ...mapGetters({
      lineOptions: "smrtResource/lineOptions",
      canSelectLine: "auth/canSelectLine",
    }),
    stationOptions() {
      return this.$store.getters["smrtResource/stationOptionsByLineId"](
        get(this.filters.line, "value")
      );
    },
    acknowledgements() {
      return [
        {
          label: "Pending",
          value: false,
        },
        {
          label: "Completed",
          value: true,
        },
      ];
    },
    isExpiredOptions() {
      return [
        {
          label: "Yes",
          value: true,
        },
        {
          label: "No",
          value: false,
        },
      ];
    },
    queryParams() {
      let params = {};
      if (!isEmpty(this.filters.cpUpdateNo)) {
        params["search"] = this.filters.cpUpdateNo;
      }
      if(this.filters.line) {
        params["lineId"] = this.filters.line.value;
      }
      if (this.filters.stations) {
        params["stationIds"] = this.filters.stations.map(
          (station) => station.value
        );
      }
      if (
        this.filters.notificationDate &&
        this.filters.notificationDate.start &&
        this.filters.notificationDate.end
      ) {
        params["notificationDateFrom"] = parseDateTimeToUtc(parseDateTime(this.filters.notificationDate.start, false));
        params["notificationDateTo"] = parseDateTimeToUtc(parseDateTime(this.filters.notificationDate.end, false) + " 23:59:59");
      }
      if (
        this.filters.acknowledgedByDate &&
        this.filters.acknowledgedByDate.start &&
        this.filters.acknowledgedByDate.end
      ) {
        params["acknowledgedByDateFrom"] = parseDateTimeToUtc(parseDateTime(this.filters.acknowledgedByDate.start, false));
        params["acknowledgedByDateTo"] = parseDateTimeToUtc(parseDateTime(this.filters.acknowledgedByDate.end, false) + " 23:59:59");
      }
      if (this.filters.isAcknowledgedOnly !== null) {
        params["isAcknowledgedOnly"] = this.filters.isAcknowledgedOnly;
      }
      if (this.filters.isExpiredOnly !== null) {
        params["isExpiredOnly"] = this.filters.isExpiredOnly;
      }
      return params;
    },
    stationList() {
      if (!this.itemList) return [];
      const groupByStations = groupBy(
        this.itemList,
        (item) => item.recipientStationName
      );
      const stationNames = Object.keys(groupByStations).sort();
      return stationNames.map((station) => ({
        station,
        line: get(groupByStations, `${station}.0.recipientLine`),
        total: groupByStations[station].length,
        acknowledged: groupByStations[station].filter(
          (item) => item.isAcknowledged
        ).length,
        pendingAcknowledgement: groupByStations[station].filter(
          (item) => !item.isAcknowledged
        ).length,
      }));
    },
  },
  methods: {
    get,
    displayUtcDate,
    checkURLQuery() {
      if (this.$route.query && this.$route.query.search) {
        this.filters.cpUpdateNo = this.$route.query.search;
        this.filters.notificationDate = {};
        this.filters.acknowledgedByDate = {}; 
      }
    },
    acknowlegementStatus({ isAcknowledged, cpUpdateDetails }) {
      const expiryDate = moment(get(cpUpdateDetails, "expiryDate"));
      const today = moment(Date.now());
      const isExpired =
        expiryDate.isValid() && expiryDate.isBefore(today, "day");
      if (isAcknowledged) return "Acknowledged";
      else if (isExpired) return "Expired";
      else {
        return "Pending Acknowledgement";
      }
    },
    resetFilters() {
      this.filters.cpUpdateNo = null;
      this.filters.line = null;
      this.filters.stations = null;
      this.filters.notificationDate = {
        start: moment()
          .subtract(1, "months")
          .toDate(),
        end: moment()
          .add(1, "months")
          .toDate(),
      };
      this.filters.acknowledgedByDate = {
        start: moment()
          .subtract(1, "months")
          .toDate(),
        end: moment()
          .add(1, "months")
          .toDate(),
      };
      this.filters.isAcknowledgedOnly = null;
      this.filters.isExpiredOnly = null;
    },
    async getPageData(status) {
      if(status) setStorage("cp-update-reports", this.filters);
      this.isLoading = true;
      const resp = await getAcknowledgementSummary({
        ...this.queryParams,
      });
      if (resp.success) {
        this.itemList = get(resp.payload, "list", []);
        this.isLoading = false;
        this.error = null;
      } else {
        this.isLoading = false;
        this.error = resp.payload;
        this.$scrollTop();
        return;
      }
    },
    navigateToView(selectedItem) {
      this.$router.push({
        name: "View CP Update",
        params: selectedItem,
        query: { queryReport: JSON.stringify(this.filters) }
      });
    },
  },
};
</script>
